import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./VideoAudioCall.css";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../NoDataFound/NoDataFound";
import { Picker, EmojiData } from "emoji-mart";
import {
  addVideoCallMessageContent,
  fetchVideoCallChatMessageStart,
} from "../../store/actions/VideoCallAction";
import io from "socket.io-client";
import configuration from "react-global-configuration";

export const googleTranslate = require("google-translate")(
  "AIzaSyBXGOi0eJOObQq8_suqta4B8GrxMkC2VaA"
);
var languages = require("language-list")();
let chatSocket;

const NewVideoAudioCallChatIndex = (props) => {
  const [emojiPickerState, SetEmojiPicker] = useState(false);
  const [languageCodes, setLanguageCodes] = useState([]);
  const [language, setLanguage] = useState("en");
  const [inputMessage, setInputMessage] = useState("");

  const handleEmojiSelect = (emoji) => {
    SetEmojiPicker(false);
    setInputMessage(inputMessage + emoji.native);
  };

  function triggerPicker(event) {
    event.preventDefault();
    SetEmojiPicker(!emojiPickerState);
  }

  useEffect(() => {
    setLanguageCodes(languages.getData());
  }, []);

  const changeHandler = async (lang) => {
    setLanguage(lang);
  };

  const handleChatSubmit = (event, chatText) => {
    event.preventDefault();

    if (chatText) {
      let chatData = [
        {
          loggedin_user_id: localStorage.getItem("userId"),
          user_id: localStorage.getItem("userId"),
          model_id: props.singleVideoCall.data.video_call_request.model_id,
          video_call_request_id:
            props.singleVideoCall.data.video_call_request.video_call_request_id,
          message: chatText,
          from_username: localStorage.getItem("username"),
          from_userpicture: localStorage.getItem("user_picture"),
          video_call_unique_id:
            props.singleVideoCall.data.video_call_request
              .video_call_request_unique_id,
          created: new Date(),
        },
      ];
      chatSocket.emit("video call chat", chatData[0]);
      props.dispatch(addVideoCallMessageContent(chatData));
      setInputMessage("");
    }
  };

  useEffect(() => {
    scrollDownChat();
  }, [props.videoCallChatMessage.data]);

  const scrollDownChat = () => {
    const parent = document.querySelector("#chat-container");
    const objDiv = document.querySelector(".video-call-chat-room");
    if (objDiv != null) {
      parent.scrollTop = objDiv.scrollHeight;
    }
  };

  useEffect(() => {
    if (!props.singleVideoCall.loading) {
      chatSocketConnect(
        props.singleVideoCall.data.video_call_request
          .video_call_request_unique_id
      );
    }
  }, [props.singleVideoCall.loading]);

  const chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (
      chatSocketUrl &&
      props.singleVideoCall.data.video_call_request.video_call_request_unique_id
    ) {
      chatSocket = io(chatSocketUrl, {
        query:
          `room : video_call_` +
          props.singleVideoCall.data.video_call_request
            .video_call_request_unique_id +
          ``,
      });

      chatSocket.emit("video call update sender", {
        commonid:
          `video_call_` +
          props.singleVideoCall.data.video_call_request
            .video_call_request_unique_id +
          ``,
      });

      chatSocket.on("video call chat", (newData) => {
        let content = [];
        content.push(newData);
        props.dispatch(addVideoCallMessageContent(content));
      });
    }
  };

  const newChatSubmit = async (event) => {
    event.preventDefault();
    if (language != 'en') {
      let transQuestion = "";
      const translating = (transQuestion) => {
        if (inputMessage !== transQuestion) {
          setInputMessage(transQuestion);
          handleChatSubmit(event, transQuestion);
        }
      };
      console.log("inputMessage" + inputMessage);
      googleTranslate.translate(
        inputMessage,
        language,
        function (err, transResp) {
          transQuestion = transResp.translatedText;
          console.log(transResp);
          translating(transResp.translatedText);
        }
      );
    } else {
      handleChatSubmit(event, inputMessage);
    }
  };

  return (
    <>
      <div className="video-call-chat-sec">
        <div
          className="video-call-chat-box"
          style={
            props.audio
              ? {}
              : {
                  height: "calc(100vh - 108px)",
                }
          }
        >
          <div className="video-call-chat-header">
            <h3>Chat</h3>
          </div>
          <div className="video-call-chat-body">
            <div
              className={
                props.audio ? "video-call-chat-box" : "new-video-call-chat-box"
              }
              id="chat-container"
              style={
                props.audio
                  ? {
                      maxHeight: "calc(100vh - 192px)",
                      overflowY: "auto",
                      paddingRight: "1em",
                    }
                  : {
                      maxHeight: "calc(100vh - 249px)",
                      overflowY: "auto",
                      paddingRight: "1em",
                    }
              }
            >
              {/* <div className="video-call-date">Today</div> */}
              <div className="video-call-chat-room">
                {props.videoCallChatMessage.data.length > 0 ? (
                  <>
                    {props.videoCallChatMessage.data.map((message, index) => (
                      <div key={index}>
                        {message.user_id == localStorage.getItem("userId") ? (
                          <>
                            <div className="video-call-chat-right">
                              <div className="video-call-chat-bg">
                                <p>{message.message}</p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="video-call-chat-left">
                              <div className="video-call-chat-bg">
                                <p>{message.message}</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
          <div className="video-call-chat-footer">
            <div className="video-call-add-message-sec">
              <form onSubmit={newChatSubmit}>
                <InputGroup className="mb-0">
                  {/* <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      <div className="upload-btn-wrapper">
                        <div className="icon-btn">
                          <Image
                            className="attach-icon"
                            src={
                              window.location.origin +
                              "/assets/images/video-call/attach.svg"
                            }
                          />
                        </div>
                        <input type="file" name="myfile" />
                      </div>
                    </InputGroup.Text>
                  </InputGroup.Prepend> */}
                  <FormControl
                    placeholder={t("type_your_message_here")}
                    aria-describedby="basic-addon1"
                    value={inputMessage}
                    onChange={(event) => setInputMessage(event.target.value)}
                  />
                  <Form.Group className="mb-0 resp-w-full new-select-language">
                    <Form.Control
                      as="select"
                      className=""
                      id="inlineFormCustomSelect"
                      custom
                      name="language"
                      value={language}
                      onChange={(e) => changeHandler(e.target.value)}
                    >
                      <option value="">Select Language</option>
                      <option value="en">English(en)</option>
                      <option value="pt">Portuguese(pt)</option>
                      <option value="es">Spanish(es)</option>
                      <option value="fr">French(fr)</option>
                    </Form.Control>
                  </Form.Group>
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Button className="icon-btn" onClick={triggerPicker}>
                        <Image
                          className="smiley-icon"
                          src={
                            window.location.origin +
                            "/assets/images/video-call/smiley.svg"
                          }
                        />
                      </Button>
                    </InputGroup.Text>
                  </InputGroup.Append>
                  {emojiPickerState && (
                    <div className="emojiWrapper chat-emoji">
                      <Picker
                        title=""
                        emoji="point_up"
                        onSelect={(emoji) => handleEmojiSelect(emoji)}
                      />
                    </div>
                  )}
                </InputGroup>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  videoCallChatMessage: state.videocall.videoCallChatMessage,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewVideoAudioCallChatIndex));
